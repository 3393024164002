<template>
  <div style="padding-top: 5px">
    <ProductTable />
    <ProductPagination />
  </div>
</template>

<script>
import ProductTable from './ProductTable';
import ProductPagination from './ProductPagination';
export default {
  name: 'ProductContainer',
  components: { ProductPagination, ProductTable },
};
</script>
