<template>
  <div>
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'产品管理'"
      :icon="'el-icon-goods'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="productData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="productData[item.prop]" />
          </el-form-item>
          <el-form-item required label-width="110px" label="负责人">
            <el-select
              style="width: 100%"
              v-model="insertID['principalID']"
              placeholder="请选择"
            >
              <el-option
                v-for="item in staff_store.staff_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item required label-width="110px" label="创建人">
            <el-select
              style="width: 100%"
              v-model="insertID['creatorID']"
              placeholder="请选择"
            >
              <el-option
                v-for="item in staff_store.staff_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="product_store.product_info"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
          <el-form-item required label-width="110px" label="负责人">
            <el-select
              style="width: 100%"
              v-model="updateID['principalID']"
              placeholder="请选择"
            >
              <el-option
                v-for="item in staff_store.staff_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item required label-width="110px" label="创建人">
            <el-select
              style="width: 100%"
              v-model="updateID['creatorID']"
              placeholder="请选择"
            >
              <el-option
                v-for="item in staff_store.staff_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '../common/Table';
import TableHeader from '../common/TableHeader';
import FilterHeader from '../common/FilterHeader';
import {
  DELETE_PRODUCT_INFO,
  GET_PRODUCT_INFO,
  GET_STAFF_INFO,
  INSERT_PRODUCT_INFO,
  UPDATE_PRODUCT_INFO,
} from '../../store/type';
import { showMsg } from '../../util/msg';
import { mapState } from 'vuex';
export default {
  name: 'ProductTable',
  components: { FilterHeader, TableHeader, Table },
  data() {
    return {
      columnConfig: [
        { prop: 'name', label: '商品名称' },
        { prop: 'price', label: '商品价格' },
        { prop: 'desc', label: '商品描述' },
        { prop: 'remark', label: '商品备注' },
        { prop: 'unit', label: '计量单位' },
        { prop: 'principal.name', label: '负责人', show: false },
        { prop: 'principal.sex', label: '性别', show: false },
        { prop: 'principal.age', label: '年龄', show: false },
        { prop: 'principal.createdAt', label: '创建日期', show: false },
        { prop: 'principal.updatedAt', label: '更新日期', show: false },
      ],
      showDelBtn: false,
      delData: [],
      productData: {},
      rowData: {},
      updateID: {},
      insertID: {},
    };
  },
  methods: {
    formStaffInfo(data, id) {
      return {
        name: data.name,
        price: data.price,
        desc: data.desc,
        remark: data.remark,
        unit: data.unit,
        principal: id.principalID,
        creator: id.creatorID,
        id: data.id,
      };
    },
    checkSelect(s) {
      return !s['principalID'] || !s['creatorID'];
    },
    deleteTableData() {
      this.$store.dispatch(DELETE_PRODUCT_INFO, this.delData);
      showMsg('success', '删除成功！');
    },
    insertCustomer() {
      if (this.checkSelect(this.insertID)) {
        showMsg('error', '请检查您的选择！', false);
        return;
      }
      this.$store.dispatch(
        INSERT_PRODUCT_INFO,
        this.formStaffInfo(this.productData, this.insertID),
      );
      showMsg('success', '插入成功！');
    },
    updateData() {
      if (this.checkSelect(this.updateID)) {
        showMsg('error', '请检查您的选择！', false);
        return;
      }
      this.$store.dispatch(
        UPDATE_PRODUCT_INFO,
        this.formStaffInfo(this.rowData, this.updateID),
      );
      showMsg('success', '更新成功！');
    },
    getDelData(val) {
      this.showDelBtn = val.length > 0;
      this.delData = val;
    },
    editData(data) {
      this.rowData = data;
    },
  },
  computed: {
    ...mapState({
      product_store: state => state.product_store,
      staff_store: state => state.staff_store,
    }),
  },
  created() {
    this.$store.dispatch(GET_PRODUCT_INFO);
    // 这里为了获取员工列表直接请求了整个表，可能会影响效率什么的
    this.$store.dispatch(GET_STAFF_INFO, {
      url: '/employees',
      method: 'get',
    });
  },
};
</script>
